<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="grey darken-1"
                dark
                class="text-h5"
            >Note
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
           <v-container fluid>
                <v-row no-gutters class="px-12 mt-8">
                    <v-textarea
                        auto-grow
                        rows="1"
                        label="Note giornaliere"
                        outlined
                        v-model="uwdNotes"
                    />
                </v-row>
            </v-container>
            <v-card-actions class="justify-end">
                <v-btn
                    color="orange lighten-2"
                    class="px-4"
                    @click="removeNotes"
                    :disabled="notes === ''"
                >  
                    <v-icon left>
                        mdi-close
                    </v-icon>
                    Rimuovi
                </v-btn>
                <v-btn
                    color="green accent-2"
                    class="px-4"
                    @click="saveMethod"
                    :disabled="saveDisabled"
                >  
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Salva
                </v-btn>
            </v-card-actions>   
        </v-card>  
    </v-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            uwdNotes: '',
        }
    },
    props: {
        notes: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        cancelMethod: function() {
            this.setEmptyNote();
            this.$emit('close-notes-edit-dialog');
        },
        saveMethod: function() {
            if(!this.saveDisabled) {
                this.$emit('save-notes', this.uwdNotes);
                this.$emit('close-notes-edit-dialog');
            }
        },
        removeNotes: function() {
            this.setEmptyNote();
            this.saveMethod();
        },
        setEmptyNote: function() {
            this.uwdNotes = '';
        }
    },
    watch: {
        show: function(val){
            if (val) {
                this.uwdNotes = this.notes;
            }
        }
    },
    computed: {
        saveDisabled: function() {
            return this.uwdNotes === this.notes;
        }
    }
}
</script>